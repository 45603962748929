define("discourse/plugins/retort/discourse/initializers/retort-init", ["exports", "@ember/object", "@ember/runloop", "@ember/service", "@popperjs/core", "pretty-text/emoji", "discourse/lib/plugin-api", "discourse/lib/text", "discourse/models/user", "discourse-common/utils/decorators"], function (_exports, _object, _runloop, _service, _core, _emoji, _pluginApi, _text, _user, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const PLUGIN_ID = "retort";
  function initializePlugin(api) {
    var _dec, _dec2, _dec3, _obj;
    const {
      retort_enabled,
      retort_allowed_emojis,
      retort_limited_emoji_set
    } = api.container.lookup("site-settings:main");
    if (!retort_enabled) {
      return;
    }
    api.decorateWidget("post-menu:before-extra-controls", helper => {
      let currentUser = api.container.lookup("service:currentUser");
      if (currentUser) {
        let retort = api.container.lookup("service:retort");
        let postId = helper.getModel().id;
        let post = retort.postFor(postId);
        if (retort.disabledFor(postId)) {
          return;
        }
        retort.storeWidget(helper);
        if (!post.retorts) {
          return;
        }
        const retorts = post.retorts.map(_ref => {
          let {
            usernames,
            emoji
          } = _ref;
          return helper.attach("retort-toggle", {
            post,
            usernames,
            emoji
          });
        });
        return helper.h("div.post-retort-container", retorts);
      } else {
        return "";
      }
    });
    api.addPostClassesCallback(attrs => {
      if (api.container.isDestroyed) {
        return [];
      }
      let retort = api.container.lookup("service:retort");
      if (!retort.disabledFor(attrs.id)) {
        return ["retort"];
      }
    });
    if (!_user.default.current()) {
      return;
    }
    api.modifyClass("route:topic", {
      pluginId: PLUGIN_ID,
      retort: (0, _service.service)(),
      setupController(controller, model) {
        this.retort.model = model;
        this.retort.initBus();
        this._super(controller, model);
      }
    });
    api.addPostMenuButton("retort", attrs => {
      const retort = api.container.lookup("service:retort");
      if (retort.disabledFor(attrs.id)) {
        return;
      }
      return {
        action: "clickRetort",
        icon: "far-smile",
        title: "retort.title",
        position: "first",
        className: "retort"
      };
    });
    api.attachWidgetAction("post-menu", "clickRetort", function () {
      const retort = api.container.lookup("service:retort");
      retort.openPicker(this.findAncestorModel());
    });
    api.modifyClass("component:emoji-picker", (_dec = (0, _decorators.default)("forRetort"), _dec2 = (0, _decorators.default)("isActive"), _dec3 = (0, _decorators.observes)("isActive"), (_obj = {
      pluginId: PLUGIN_ID,
      retort: (0, _service.service)(),
      limited() {
        return this.forRetort && retort_limited_emoji_set;
      },
      activeRetort() {
        return this.forRetort && this.isActive;
      },
      _setup() {
        if (this.forRetort) {
          this._setupRetort();
        } else {
          this._super();
        }
      },
      init() {
        this._super();
        this._setup();
      },
      _setupRetort() {
        if (this.isActive) {
          this.onShowRetort();
        } else {
          this.onClose();
        }
      },
      onShowRetort() {
        if (!this.limited) {
          this.set("isLoading", true);
        }
        (0, _runloop.schedule)("afterRender", () => {
          document.addEventListener("click", this.handleOutsideClick);
          const post = this.post;
          const emojiPicker = document.querySelector(".emoji-picker");
          const retortButton = document.querySelector(`
          article[data-post-id="${post.id}"] .post-controls .retort`);
          if (!emojiPicker || !retortButton) {
            return false;
          }
          if (!this.site.isMobileDevice) {
            this._popper = (0, _core.createPopper)(retortButton, emojiPicker, {
              placement: this.limited ? "top" : "bottom"
            });
          }
          if (this.limited) {
            const emojis = retort_allowed_emojis.split("|");
            emojiPicker.innerHTML = `
            <div class='limited-emoji-set'>
              ${emojis.map(code => `<img
                src="${(0, _text.emojiUrlFor)(code)}"
                width=40
                height=40
                title='${code}'
                class='emoji' />`).join("")}
            </div>
          `;
            emojiPicker.classList.add("has-limited-set");
            emojiPicker.onclick = e => {
              if (e.target.classList.contains("emoji")) {
                this.emojiSelected(e.target.title);
              } else {
                this.set("isActive", false);
                this.onClose();
              }
            };
          } else {
            emojiPicker.querySelectorAll(".emojis-container .section .section-header").forEach(p => this._sectionObserver.observe(p));
            (0, _runloop.later)(() => {
              this.set("isLoading", false);
              this.applyDiscourseTrick(emojiPicker);
            }, 50);
          }
        });
      },
      // Lifted from onShow in emoji-picker. See note in that function concerning its utility.
      applyDiscourseTrick(emojiPicker) {
        (0, _runloop.schedule)("afterRender", () => {
          if (!this.site.isMobileDevice || this.isEditorFocused) {
            const filter = emojiPicker.querySelector("input.filter");
            filter && filter.focus();
          }
          if (this.selectedDiversity !== 0) {
            this._applyDiversity(this.selectedDiversity);
          }
        });
      },
      onCategorySelection(sectionName) {
        const section = document.querySelector(`.emoji-picker-emoji-area .section[data-section="${sectionName}"]`);
        section && section.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest"
        });
      },
      onFilter(event) {
        const emojiPickerArea = document.querySelector(".emoji-picker-emoji-area");
        const emojisContainer = emojiPickerArea.querySelector(".emojis-container");
        const results = emojiPickerArea.querySelector(".results");
        results.innerHTML = "";
        if (event.target.value) {
          results.innerHTML = (0, _emoji.emojiSearch)(event.target.value.toLowerCase(), {
            maxResults: 10,
            diversity: this.emojiStore.diversity
          }).map(this._replaceEmoji).join("");
          emojisContainer.style.visibility = "hidden";
          results.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest"
          });
        } else {
          emojisContainer.style.visibility = "visible";
        }
      },
      _emojisPerRow: {
        0: 1,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 3,
        7: 3,
        8: 4,
        9: 3,
        10: 5,
        11: 5,
        12: 4,
        13: 5,
        14: 7,
        15: 5,
        16: 4,
        17: 5,
        18: 6,
        19: 6,
        20: 5,
        21: 7,
        22: 5,
        23: 5,
        24: 6
      }
    }, (_applyDecoratedDescriptor(_obj, "limited", [_dec], Object.getOwnPropertyDescriptor(_obj, "limited"), _obj), _applyDecoratedDescriptor(_obj, "activeRetort", [_dec2], Object.getOwnPropertyDescriptor(_obj, "activeRetort"), _obj), _applyDecoratedDescriptor(_obj, "_setup", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_setup"), _obj), _applyDecoratedDescriptor(_obj, "onShowRetort", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onShowRetort"), _obj), _applyDecoratedDescriptor(_obj, "onCategorySelection", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onCategorySelection"), _obj), _applyDecoratedDescriptor(_obj, "onFilter", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onFilter"), _obj)), _obj)));
  }
  var _default = _exports.default = {
    name: "retort-button",
    initialize: function () {
      (0, _pluginApi.withPluginApi)("0.8.6", api => initializePlugin(api));
    }
  };
});